import { fetchJs, scriptParams } from './utils/script'
import { envScriptUrl } from './config'
import { resolveEnv, initArms } from './utils/arms'
import uuk from './utils/uuk'

try {
  const w = window
  const envs = ['branch', 'trunk', 'prepare', 'online']
  const spaModes = ['hash', 'history']
  const params = scriptParams || {}
  let sample = params.sample ? parseInt(params.sample) : 100
  if (!sample || sample > 100 || sample < 0) {
    sample = 100
  }

  if (params.env && envs.includes(params.env)) {
    w.benlaiEnv = params.env as BenlaiEnv
  }
  let { benlaiEnv } =  w

  if (!benlaiEnv) {
    fetchJs(envScriptUrl, () => {
      benlaiEnv = w.benlaiEnv
      init()
    })
  } else {
    init()
  }

  function init() {
    if (!params.pid) {
      console.error('[arms]: arms pid失踪了, 请协助找寻~~~')
      return
    }
    if (!benlaiEnv) {
      console.error('[arms]: env失踪了, 请协助找寻~~~')
      return
    }
    const armsEnv = resolveEnv(benlaiEnv)
    const initParams: BenlaiArmsParams = { pid: params.pid, env: armsEnv, uuk, sample, spaMode: 'history' }

    if (params.spa && spaModes.includes(params.spa)) {
      initParams.spaMode = params.spa as SPAMode
    }
    initArms(initParams)
  }
} catch (error) {
  console.error(error)
  console.error('[arms]: arms初始化失败, 请协助找寻~~~')
}


