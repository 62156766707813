import cookie from './cookie'
import { v4 } from 'uuid'
import { scriptParams } from './script'

const UUK_KEY = scriptParams?.uuk || 'uuk'
let uuk: string = cookie.get(UUK_KEY) || ''

if (!uuk) {
  uuk = v4()
  cookie.set(UUK_KEY, uuk)
}

window.bUUK = uuk

export default uuk

export let ssoUUK: string = cookie.get('uuk') || ''
if (!ssoUUK) {
  ssoUUK = v4()
  cookie.set('uuk', ssoUUK)
}
