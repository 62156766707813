// import ArmsRum from '@arms/rum-browser'
import { fetchJs } from './script';
import { armsEndpoint, armsSdk } from '../config'

import { version } from '../../package.json'

export const resolveEnv = (benlaiEnv: BenlaiEnv): ArmsEnv => {
  switch (benlaiEnv) {
    case 'branch':
      return 'local';
    case 'trunk':
      return 'daily';
    case 'prepare':
      return 'pre';
    default:
      return 'prod';
  }
}

export const initArms = (params: BenlaiArmsParams) => {
  window.__rum = {
    pid: params.pid,
    endpoint: armsEndpoint,
    env: params.env,
    spaMode: params.spaMode,
    user: {
      // name: () => '',
      id: params.uuk,
      tags: params.uuk,
    },
    tracing: {
      enable: true,
      sample: params.sample || 100,
      tracestate: true,
      allowedUrls: [(url: string)=>url.includes('.benlai.com')]
    }
  }
  fetchJs(armsSdk, () => {
    console.log(`arms init success by benlai-fe-tools verion ${version}`)

    window.setArmsUserName = (name: string) => {
      try {
        const arms = window.RumSDK.default
        const config = arms.getConfig()
        const user = config.user
        arms.setConfig('user', {
          ...user,
          name
        })
      } catch (error) {
        console.error(error)
      }
    }
  })
}


