export const fetchJs = (url: string, cb: () => void = () => {}) => {
  const el: HTMLScriptElement = document.createElement('script')
  let loaded = false
  el.type = 'text\/javascript'
  // el.async = true
  el.src = url
  el.crossOrigin = 'anonymous'
  el.onload = function () {
    cb && cb()
  }
  document.head.appendChild(el)
  return el
}

export const currentScript = document.currentScript

export const scriptParams = currentScript?.dataset
